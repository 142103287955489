import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  Container,
  Flex,
  SimpleGrid,
  HStack,
  Icon,
  VStack,
} from "@chakra-ui/react";
import Finance from "../assets/Finance.svg";

import webDev from "../assets/availability-svgrepo-com.svg";
import automation from "../assets/system-settings-svgrepo-com.svg";
import api from "../assets/api-interface-svgrepo-com.svg";
import devOps from "../assets/host-record-svgrepo-com.svg";
import { CheckIcon } from "@chakra-ui/icons";

const features = [
  {
    id: 1,
    title: "Hosting Fee Included",
    text: "Hosting fees are built into the monthly payment.",
  },
  {
    id: 2,
    title: "Unlimited Edits",
    text: "Change anything you want, whenever you want, guranteed within 24 hours.",
  },
  {
    id: 3,
    title: "24/7 Customer Service",
    text: "Call direct - any time of day or night.",
  },
  {
    id: 4,
    title: "Web Design & Development",
    text: "Includes design, development, and testing of your custom website.",
  },
  {
    id: 5,
    title: "SEO Optimized",
    text: "We optimize your website for search engines to help you rank higher.",
  },
  {
    id: 6,
    title: "Google Analystics",
    text: "We install analytics for free to monitor traffic and where it comes from.",
  },
];

const SinglePrice = () => {
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", "2xl": "row" }}
      alignItems="center"
      width={"100%"}
    >
      <Box ml={{ "2xl": 250 }}>
        <Image src={Finance} alt="Image" maxW={{ base: 350, md: 500 }} />
      </Box>
      <Box mx={{ md: 150 }}>
        <Heading
          fontSize={"2xl"}
          color={"#b2bac6"}
          textAlign={{ base: "center", "2xl": "start" }}
        >
          SMALL BUSINESS OFFER
        </Heading>
        <Heading
          as="h2"
          mb={4}
          textAlign={{ base: "center", "2xl": "start" }}
          fontWeight={800}
          fontSize={50}
        >
          <Text as={"span"} color={"#1ED891"}>
            {" "}
            $0{" "}
          </Text>{" "}
          Down,{" "}
          <Text as={"span"} color={"#1ED891"}>
            {" "}
            $150{" "}
          </Text>{" "}
          Per Month, <br /> 12 Month Minimum Contract
        </Heading>
        <Text
          fontSize={20}
          color={"text"}
          textAlign={{ base: "center", "2xl": "start" }}
        >
          $0 down for a standard 5-page small business website. If you need more
          than that, we can discuss a custom plan for you.
          <br />
          <br />
          You own your domain, content, listing, and profiles. Cancel anytime
          with no fees or hassle.
        </Text>
        <Container maxW={"full"} mt={10} pb={{ base: 20, md: 0 }}>
          <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={"top"}>
                <Box color={"secondaryHighlight"} px={2}>
                  <Icon as={CheckIcon} color={"#1ED891"} />
                </Box>
                <VStack align={"start"}>
                  <Text fontWeight={700} fontSize={"20"} color={"black"}>
                    {feature.title}
                  </Text>
                  <Text color={"text"} fontSize={"lg"}>
                    {feature.text}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    </Box>
  );
};

export default SinglePrice;
