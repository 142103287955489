import React from "react";
import {
  Box,
  Text,
  Card,
  CardBody,
  Image,
  Stack,
  Heading,
  Button,
} from "@chakra-ui/react";

const CustomCard = (props) => {
  const { image, heading, text, margin, link } = props;

  return (
    <Card
      // height={{ base: "600", md: "620" }}

      mx={7}
      mb={{ base: margin, xl: margin }}

      // maxW={{ base: "100%", xl: "30%" }}
    >
      <CardBody>
        <Image
          src={image}
          alt="Green double couch with wooden legs"
          borderRadius="lg"
          width={500}
        />
        <Stack mt="6" spacing="3">
          <Heading size="md">
            {heading}
            {link ? (
              <React.Fragment>
                &nbsp;|&nbsp;
                <Button
                  as="a"
                  href={link}
                  variant="link"
                  color="#1ED891"
                  _hover={{ textDecoration: "underline" }}
                >
                  Check it out!
                </Button>
              </React.Fragment>
            ) : null}
          </Heading>
          <Text>{text}</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default CustomCard;
