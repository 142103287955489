"use client";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
// import { useNavigate } from "react-router-dom";
import logo from "../assets/Cybria (5).png";
function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

export default function Navbar({ backgroundColor }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMdOrLarger = useBreakpointValue({ base: false, md: true });

  //   const navigate = useNavigate();

  const links = [
    {
      name: "Home",
      link: "landing",
    },
    {
      name: "Services",
      link: "services",
    },

    {
      name: "Our Work",
      link: "portfolio",
    },
    {
      name: "Packages",
      link: "packages",
    },
    {
      name: "About",
      link: "about",
    },
    // {
    //   name: "Contact",
    //   link: "contact",
    // },
  ];

  return (
    <>
      <Box
        bg="white"
        textColor={"black"}
        px={4}
        py={3}
        position={isMdOrLarger ? "fixed" : "static"} // Set position to "fixed" for md and larger screens, otherwise set to "static"
        top={0}
        left={0}
        right={0}
        width="100%"
        zIndex={999}
        boxShadow={isMdOrLarger ? "md" : "none"} // Apply boxShadow for md and larger screens, otherwise set to "none"
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              {/* <Image src={logo} width="150px" /> */}
              <Image src={logo} maxW={200} />
            </Box>
          </HStack>
          <HStack
            pl={20}
            as={"nav"}
            spacing={4}
            display={{ base: "none", md: "flex" }}
          >
            {links.map((link) => (
              <Button
                fontSize={"17"}
                variant={"nav"}
                css={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover::after": {
                    width: "100%",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    height: "3px",
                    left: 0,
                    bottom: 0,
                    width: 0,
                    background: "#1ED891",
                    transition: "width .2s",
                  },
                }}
                fontWeight={400}
                onClick={() => scrollToSection(link.link)}
              >
                {link.name}
              </Button>
            ))}
          </HStack>
          <Flex alignItems={"center"}>
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <Button
                as={"a"}
                display={{ base: "none", md: "inline-flex" }}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                _hover={{
                  bg: "pink.300",
                }}
                onClick={() => scrollToSection("contact")}
                fontSize={{ base: "auto", md: "lg" }}
              >
                Get in touch
              </Button>
            </Stack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {links.map((link) => (
                <Button
                  variant={"nav"}
                  key={link.link}
                  onClick={() => scrollToSection(link.link)}
                  fontWeight={400}
                >
                  {link.name}
                </Button>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
