import React from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  Flex,
  Card,
  Image,
  Stack,
  HStack,
  Button,
  Divider,
  SimpleGrid,
  VStack,
  Icon,
  Grid,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { CiSearch } from "react-icons/ci";
import Carousel from "../components/Carousel";
import Testimonals from "../components/Testimonials";
import CustomCard from "../components/CustomCard";

import graphic from "../assets/129-Inspired-with-ideas.svg";
import webDev from "../assets/availability-svgrepo-com.svg";
import automation from "../assets/system-settings-svgrepo-com.svg";
import api from "../assets/api-interface-svgrepo-com.svg";
import devOps from "../assets/host-record-svgrepo-com.svg";
import Pricing from "../components/Pricing";
import SinglePrice from "../components/SinglePrice";
import work from "../assets/portfolio-job.png";
import work2 from "../assets/portfolio-job-4.png";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Me from "../assets/Headshot2023-4 - Copy.jpg";
import work3 from "../assets/Untitled (2000 x 1500 px) (6).png";
import work4 from "../assets/Untitled (2000 x 1500 px) (10).png";
const features = [
  {
    id: 1,
    icon: webDev,
    title: "Web Solutions",
    text: "Elevate your digital presence with tailored web solutions, ranging from sleek static websites to dynamic web applications designed to captivate and engage your audience.",
  },
  {
    id: 2,
    icon: automation,
    title: "Maintenance & Support",
    text: "Ensure the longevity and smooth operation of your digital assets with our comprehensive maintenance and support services, providing peace of mind for your online endeavors.",
  },
  {
    id: 3,
    icon: api,
    title: "API Integration",
    text: "Seamlessly integrate third-party APIs into your website or application, unlocking new functionalities and enhancing user experiences to meet the evolving demands of your business and customers.",
  },
  {
    id: 4,
    icon: devOps,
    title: "Infrastructure & DevOps",
    text: "Streamline your project's infrastructure and automate deployment processes with our robust DevOps solutions, ensuring scalability, reliability, and efficiency throughout the development lifecycle.",
  },
];

function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

const Landing = () => {
  return (
    <>
      <Navbar />
      <Box maxW={"100%"}>
        {/* <Box maxW={"100%"}>
        <Box
          py={{ base: 5, md: 20 }}
          pt={{ base: 0, md: 40 }}
          maxW="100%"
          //   minH={"100vh"}
          display="flex"
          id="landing"
          flexDirection={{ base: "column", md: "row" }}
          //   justifyContent={{ base: "center", md: "space-between" }}
          textAlign={{ base: "center", md: "left" }}
          mx={{ base: 10, md: 0 }}
        >
          <Box
            alignItems={"center"}
            // flex="1"
            maxW={{ base: "", md: "4xl" }}
            // mb={{ base: 0, md: 0 }}
            ml={{ md: 40 }}
          >
            <Stack as={Box} pt={{ base: 20, md: 20 }} ml={{ lg: 20 }}>
              <Heading
                fontWeight={900}
                fontSize={{ base: "5xl", md: "6xl" }}
                pb={{ base: 5, md: 0 }}
                px={{ base: 2, md: 0 }}
                lineHeight={"110%"}
              >
                Let's build something
                <Text as={"span"} color={"#1ED891"}>
                  {" "}
                  amazing{" "}
                </Text>
                together.
              </Heading>
              <Text
                color={"text"}
                fontSize={20}
                fontWeight={400}
                px={{ base: 10, md: 0 }}
              >
                Empowering small businesses to thrive in the digital realm is
                our mission. Whether it's crafting elegant websites or
                developing intricate applications, we're your trusted partner
                every step of the way.
              </Text>
              <Box
                pt={15}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={{ base: "center", md: "start" }}
              >
                <Button
                  colorScheme="blue"
                  bg="blue.400"
                  color="white"
                  _hover={{
                    bg: "blue.500",
                  }}
                  mr={5}
                  width={{ base: "auto", md: "auto", lg: "30%" }}
                  fontSize={{ base: "auto", md: "lg" }}
                  onClick={() => scrollToSection("contact")}
                >
                  Get in touch
                </Button>
                <Button
                  fontSize={{ base: "auto", md: "lg" }}
                  onClick={() => scrollToSection("services")}
                >
                  Learn more
                </Button>
              </Box>
            </Stack>
          </Box>
          <Image
            alignSelf={"start"}
            ml={{ lg: 100 }}
            pr={{ md: 150 }}
            maxW={700}
            src={graphic}
          />
        </Box> */}

        <Box
          display={"flex"}
          maxW="100%"
          pt={{ base: 0, md: 40 }}
          flexDirection={{ base: "column", xl: "row" }}
          textAlign={{ base: "center", xl: "left" }}
          id="how"
          px={10}
          pb={{ base: 10, xl: 20 }}
        >
          <Box
            display={"flex"}
            w={{ base: "100%", xl: "50%" }}
            flexDirection={"column"}
            // pr={{ xl: 200 }}
            pl={{ xl: 20 }}
            pt={{ xl: 35 }}
          >
            <Stack as={Box} pt={{ base: 20, md: 20 }} ml={{ lg: 20 }}>
              <Heading
                fontWeight={900}
                fontSize={{ base: "5xl", md: "6xl" }}
                pb={{ base: 5, md: 0 }}
                px={{ base: 2, md: 0 }}
                lineHeight={"110%"}
              >
                Let's build something
                <Text as={"span"} color={"#1ED891"}>
                  {" "}
                  amazing{" "}
                </Text>
                together.
              </Heading>
              <Text
                color={"text"}
                fontSize={20}
                fontWeight={400}
                px={{ base: 10, md: 0 }}
              >
                Empowering small businesses to thrive in the digital realm is
                our mission. Whether it's crafting elegant websites or
                developing intricate applications, we're your trusted partner
                every step of the way.
              </Text>
              <Box
                pt={15}
                display="flex"
                flexDirection="row"
                alignItems={{ base: "center", xl: "flex-start" }}
                justifyContent={{ base: "center", xl: "start" }}
              >
                <Button
                  colorScheme="blue"
                  bg="blue.400"
                  color="white"
                  _hover={{
                    bg: "blue.500",
                  }}
                  mr={5}
                  width={{ base: "auto", md: "auto", lg: "30%" }}
                  fontSize={{ base: "auto", md: "lg" }}
                  onClick={() => scrollToSection("contact")}
                >
                  Get in touch
                </Button>
                <Button
                  fontSize={{ base: "auto", md: "lg" }}
                  onClick={() => scrollToSection("services")}
                >
                  Learn more
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            alignItems="flex-start"
            display={"flex"}
            w={{ xl: "50%" }}
            pl={{ xl: 10 }}
            justifyContent="center"
          >
            <Image src={graphic} w={{ md: 600 }} />
          </Box>
        </Box>

        <Box
          id="services"
          py={{ base: 75, md: 150 }}
          bg={"#f9fafb"}
          textAlign={"center"}
          px={{ base: 10, md: 0 }}
        >
          <Box pb={20}>
            <Heading fontWeight={800} fontSize={50}>
              Our{" "}
              <Text as={"span"} color="primaryHighlight">
                Services
              </Text>
            </Heading>
            <Text fontSize={20} color={"text"}>
              We offer a wide range of services to help you succeed online.
            </Text>
          </Box>

          <Container maxW={"7xl"}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
              {features.map((feature) => (
                <VStack key={feature.id} align={"top"} alignItems={"center"}>
                  <Box color={"secondaryHighlight"} px={2}>
                    <Image src={feature.icon} maxW={100} />
                  </Box>
                  <VStack align={"center"}>
                    <Text fontWeight={600} fontSize={"xl"} color={"black"}>
                      {feature.title}
                    </Text>
                    <Text color={"text"} fontSize={"lg"}>
                      {feature.text}
                    </Text>
                  </VStack>
                </VStack>
              ))}
            </SimpleGrid>
          </Container>
        </Box>

        <Box
          maxW="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={{ base: 75, md: 150 }}
          px={{ base: 10, md: 0 }}
        >
          <SinglePrice />
        </Box>
        <Box
          bg={"#f9fafb"}
          display="flex"
          id="portfolio"
          flexDirection="column"
          alignItems="center"
          py={{ base: 75, xl: 150 }}
          px={{ base: 10, xl: 0 }}
        >
          <Heading
            fontWeight={800}
            fontSize={50}
            textAlign={{ base: "center", md: "start" }}
          >
            Featured{" "}
            <Text as={"span"} color={"primaryHighlight"}>
              Work
            </Text>
          </Heading>
          <Text
            pb={10}
            fontSize={20}
            color={"text"}
            textAlign={{ base: "center", md: "start" }}
          >
            Check out some projects we've worked on.
          </Text>

          {/* <Box
            display="flex"
            flexDirection={{ base: "column", xl: "row" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CustomCard
              heading="Revyoo App"
              text="Revyoo is an AI-powered resume review application that seamlessly combines sleek design, robust user authentication, and advanced OpenAI integration. It empowers job seekers with personalized resume feedback through AI. It features a credit-based payment system, and effortless payment processing"
              image={work}
              margin={10}
              link={"https://revyoo.io"}
            />
            <CustomCard
              heading="Revyoo Landing 2.0"
              text="Revyoo Landing 2.0 was a revitalization project of Revyoo App's landing page. We redesigned the page with a lighter aesthetic, and engaging components such as a rotating marquee and floating sections. The new design aimed to create a more naturally flowing user experience, based on user feedback."
              image={work3}
              margin={10}
              link={"https://revyoo.io"}
            />
            <CustomCard
              heading="Metag Store"
              text="Metag offers a modern solution for exchanging contact information seamlessly with just a tap of your phone using NFC technology. As part of this project, we developed an e-commerce platform along with designing and developing the innovative 'digital business card' page, ensuring a seamless user experience for all interactions."
              image={work2}
              margin={10}
            />
          </Box> */}
          <Grid
            mx={{ base: 0, xl: 10 }}
            templateColumns={{ base: "1fr", xl: "1fr 1fr" }}
            gap={4}
            alignItems="center"
            justifyContent="center"
          >
            <CustomCard
              heading="Revyoo App"
              text="Revyoo is an AI-powered resume review application that seamlessly combines sleek design, robust user authentication, and advanced OpenAI integration. It empowers job seekers with personalized resume feedback through AI. It features a credit-based payment system, and effortless payment processing."
              image={work}
              margin={10}
              link={"https://revyoo.io"}
            />
            <CustomCard
              heading="Revyoo Landing 2.0"
              text="Revyoo Landing 2.0 was a revitalization project of Revyoo App's landing page. We redesigned the page with a lighter aesthetic, and engaging components such as a rotating marquee and floating sections. The new design aimed to create a more naturally flowing user experience, based on user feedback."
              image={work3}
              margin={10}
              link={"https://revyoo.io"}
            />
            <CustomCard
              heading="Internal ChatGPT"
              text="The Internal ChatGPT solution is a powerful tool we've developed for our clients. It enables them to privately and securely leverage advanced ChatGPT LLMs integrated with their internal docs and knowledge base, ehancing productivity, streamlining workflows, and unlocking valuable insights."
              image={work4}
              margin={10}
            />
            <CustomCard
              heading="Metag Store"
              text="Metag offers a modern solution for exchanging contact information seamlessly with just a tap of your phone using NFC technology. As part of this project, we developed an e-commerce platform along with designing and developing the innovative 'digital business card' page, ensuring a seamless user experience for all interactions."
              image={work2}
              margin={10}
            />
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          id="packages"
          py={{ base: 75, md: 150 }}
          px={{ base: 10, md: 0 }}
        >
          <Pricing />
        </Box>
        {/* bg={"#f9fafb"} */}
        <Box bg={"#f9fafb"} py={{ base: 75, md: 150 }} id="about">
          <Box
            display="flex"
            flexDirection={{ base: "column", "2xl": "row" }}
            alignItems="center"
          >
            {/* <Box ml={{ md: 250 }}>
              <Image src={Me} alt="Image" maxW={{ base: 350, md: 500 }} />
            </Box> */}

            <Box
              ml={{ "2xl": 350 }}
              mx={20}
              // w={{ base: "200px", xl: "400px" }}
              // h={{ base: "200px", xl: "400px" }}
              // w={{ base: "200", "2xl": "400" }}
              // h={{ base: "200", "2xl": "400" }}
              w="auto"
              h="auto"
              maxW={{ base: "250px", "2xl": "400px" }}
              borderRadius="full"
              overflow="hidden"
              border="4px solid #1ED891" // Change the border color as needed
            >
              <Image
                src={Me} // Replace "your-image-url.jpg" with the URL of your image
                alt="Your Image"
                w="100%"
                h="100%"
                objectFit="cover"
              />
            </Box>
            <Box
              // width="100%"
              mx={{ base: 10, xl: 0 }}
              maxW={{ base: "100%", md: "40%" }}
            >
              <Heading
                as="h2"
                mb={4}
                textAlign={{ base: "center", "2xl": "start" }}
                fontWeight={800}
                fontSize={50}
                mt={{ base: 7, "2xl": 0 }}
              >
                About
                <Text as={"span"} color={"#1ED891"}>
                  {" "}
                  Us
                </Text>
              </Heading>
              <Text
                fontSize={20}
                mr={{ base: 0, "2xl": 20 }}
                color={"text"}
                textAlign={{ base: "center", "2xl": "start" }}
              >
                Hey there, I'm Marko, the founder of Cybria. With almost half a
                decade of hands-on experience in software development and web
                design, I've been knee-deep in code and creativity, serving both
                large enterprises and nimble startups.
                <br />
                <br />
                As a fellow business owner, I've faced the same hurdles and
                headaches that come with navigating the online world. That's why
                I'm on a mission to lend a hand to other entrepreneurs like
                yourself. Whether you're in need of a polished website or a
                game-changing app, I'm here to help your business stand out in
                the digital crowd.
                <br />
                <br />
                Let's chat and see how I can bring your vision to life.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box bg="white">
          <Contact />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Landing;
