import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Landing from "./pages/Landing";
import customTheme from "./design/customTheme";
import Carousel from "./components/Carousel";

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Landing />
      {/* <Carousel /> */}
    </ChakraProvider>
  );
}

export default App;
