"use client";

import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Stack,
  Textarea,
  Tooltip,
  useClipboard,
  useColorModeValue,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { BsGithub, BsLinkedin, BsPerson, BsTwitter } from "react-icons/bs";
import { MdEmail, MdOutlineEmail } from "react-icons/md";

import emailjs from "@emailjs/browser";

export default function Contact() {
  const toast = useToast();
  function sendEmail(name, email, company, message) {
    emailjs
      .send(
        "service_5fyps37",
        "template_tbiuacj",
        {
          from_name: name,
          email: email,
          company: company,
          message: message,
        },
        {
          publicKey: "fgPDHjSYUi6hpUcHz",
        }
      )
      .then((response) => {
        toast({
          title: "Message sent!",
          description:
            "Thanks for reaching out. We'll get back to you shortly.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "There was an error sending your message.",
          description: "Please send an email directly to cybriadev@gmail.com",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, company, message } = formData;
    sendEmail(name, email, company, message);
    // Optionally, you can reset the form after submission
    setFormData({
      name: "",
      email: "",
      company: "",
      message: "",
    });
  };

  return (
    <Flex align="center" justify="center" id="contact">
      <Box
        borderRadius="lg"
        m={{ base: 5, md: 16, lg: 10 }}
        p={{ base: 5, lg: 16 }}
      >
        <Box>
          <Heading fontWeight={800} fontSize={50} textAlign={"center"}>
            Get{" "}
            <Text as={"span"} color={"primaryHighlight"}>
              in Touch
            </Text>
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={"20"}
            color={"text"}
            width={{ base: "full", md: "xl" }}
            pb={{ base: 0, md: 10 }}
          >
            Shoot us a message, and we'll get back to you as soon as possible.
            We look forward to hearing from you!
          </Text>
          <VStack spacing={{ base: 4, md: 8, lg: 20 }}>
            <Box
              width={{ base: "full", md: "xl" }}
              //   bg={useColorModeValue("white", "gray.700")}
              borderRadius="lg"
              p={8}
              color={useColorModeValue("gray.700", "whiteAlpha.900")}
              shadow="base"
              bg={"#f9fafb"}
            >
              <VStack spacing={5}>
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>

                  <InputGroup>
                    <InputLeftElement>
                      <BsPerson />
                    </InputLeftElement>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>

                  <InputGroup>
                    <InputLeftElement>
                      <MdOutlineEmail />
                    </InputLeftElement>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Company Name</FormLabel>

                  <InputGroup>
                    <InputLeftElement>
                      <MdOutlineEmail />
                    </InputLeftElement>
                    <Input
                      type="text"
                      name="company"
                      placeholder="Your Company Name"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Message</FormLabel>

                  <Textarea
                    name="message"
                    placeholder="Please provide some details about the service you're interested in."
                    rows={6}
                    resize="none"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  colorScheme="blue"
                  bg="blue.400"
                  color="white"
                  _hover={{
                    bg: "blue.500",
                  }}
                  width="full"
                  onClick={handleSubmit}
                >
                  Send Message
                </Button>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
